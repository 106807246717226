import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/organisms/Layout/Layout';

const NotFoundPage = () => (
  <Layout>
    <Helmet title="404: Not found">
      <meta name="robots" content="noindex" />
    </Helmet>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
